import React, { useContext, Suspense, lazy } from "react";
import { ThemeContext } from "./context/ThemeContext";
import HeaderComponent from "./components/Header/HeaderComponent";
import FooterComponent from "./components/footer/FooterComponent";
import HomeContainer from "./containers/home/HomeContainer";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Search from "./components/weather/Search";
// import WeatherContainer from "./containers/weather/WeatherContainer";

function App() {
  const { theme } = useContext(ThemeContext);

  return (
    <Router>
      <div className={`bg-${theme} tracking-wider border-box wrapper`}>
        <div>
          <HeaderComponent />
        </div>
        <Switch>
          <Route path="/" exact={true} component={HomeContainer} />
          <Route path="/city" exact={true} component={Search} />
          {/* <Route path="/city-weather-dashboard" exact={true} component={WeatherContainer} /> */}
        </Switch>
        <div>
          <FooterComponent />
        </div>
      </div>
    </Router>
  );
}

export default App;
