const WEATHER_DATA = {
    lat: 35,
    lon: -80,
    timezone: 'America/New_York',
    timezone_offset: -14400,
    current: {
      dt: 1664235687,
      sunrise: 1664190851,
      sunset: 1664234097,
      temp: 72.07,
      feels_like: 71.22,
      pressure: 1008,
      humidity: 48,
      dew_point: 51.31,
      uvi: 0,
      clouds: 20,
      visibility: 10000,
      wind_speed: 6.91,
      wind_deg: 310,
      weather: [
        {id: 801, main: 'Clouds', description: 'few clouds', icon: '02n'},
      ],
    },
    hourly: [
      {
        dt: 1664233200,
        temp: 72.48,
        feels_like: 71.55,
        pressure: 1008,
        humidity: 45,
        dew_point: 49.96,
        uvi: 0,
        clouds: 16,
        visibility: 10000,
        wind_speed: 9.73,
        wind_deg: 327,
        wind_gust: 21.07,
        weather: [
          {id: 801, main: 'Clouds', description: 'few clouds', icon: '02d'},
        ],
        pop: 0,
      },
      {
        dt: 1664236800,
        temp: 72.07,
        feels_like: 71.22,
        pressure: 1008,
        humidity: 48,
        dew_point: 51.31,
        uvi: 0,
        clouds: 20,
        visibility: 10000,
        wind_speed: 9.91,
        wind_deg: 332,
        wind_gust: 24.27,
        weather: [
          {id: 801, main: 'Clouds', description: 'few clouds', icon: '02n'},
        ],
        pop: 0,
      },
      {
        dt: 1664240400,
        temp: 71.53,
        feels_like: 70.5,
        pressure: 1009,
        humidity: 45,
        dew_point: 49.1,
        uvi: 0,
        clouds: 16,
        visibility: 10000,
        wind_speed: 9.51,
        wind_deg: 325,
        wind_gust: 24.49,
        weather: [
          {id: 801, main: 'Clouds', description: 'few clouds', icon: '02n'},
        ],
        pop: 0,
      },
      {
        dt: 1664244000,
        temp: 70,
        feels_like: 68.77,
        pressure: 1010,
        humidity: 44,
        dew_point: 47.12,
        uvi: 0,
        clouds: 12,
        visibility: 10000,
        wind_speed: 8.14,
        wind_deg: 323,
        wind_gust: 22.97,
        weather: [
          {id: 801, main: 'Clouds', description: 'few clouds', icon: '02n'},
        ],
        pop: 0,
      },
      {
        dt: 1664247600,
        temp: 67.12,
        feels_like: 65.64,
        pressure: 1011,
        humidity: 45,
        dew_point: 45.09,
        uvi: 0,
        clouds: 8,
        visibility: 10000,
        wind_speed: 7.05,
        wind_deg: 328,
        wind_gust: 20.09,
        weather: [
          {id: 800, main: 'Clear', description: 'clear sky', icon: '01n'},
        ],
        pop: 0,
      },
      {
        dt: 1664251200,
        temp: 63.88,
        feels_like: 62.13,
        pressure: 1013,
        humidity: 46,
        dew_point: 42.73,
        uvi: 0,
        clouds: 4,
        visibility: 10000,
        wind_speed: 7.09,
        wind_deg: 332,
        wind_gust: 18.88,
        weather: [
          {id: 800, main: 'Clear', description: 'clear sky', icon: '01n'},
        ],
        pop: 0,
      },
      {
        dt: 1664254800,
        temp: 60.28,
        feels_like: 58.21,
        pressure: 1014,
        humidity: 47,
        dew_point: 39.52,
        uvi: 0,
        clouds: 0,
        visibility: 10000,
        wind_speed: 6.55,
        wind_deg: 332,
        wind_gust: 18.19,
        weather: [
          {id: 800, main: 'Clear', description: 'clear sky', icon: '01n'},
        ],
        pop: 0,
      },
      {
        dt: 1664258400,
        temp: 58.69,
        feels_like: 56.52,
        pressure: 1014,
        humidity: 48,
        dew_point: 38.57,
        uvi: 0,
        clouds: 0,
        visibility: 10000,
        wind_speed: 5.82,
        wind_deg: 335,
        wind_gust: 14.32,
        weather: [
          {id: 800, main: 'Clear', description: 'clear sky', icon: '01n'},
        ],
        pop: 0,
      },
      {
        dt: 1664262000,
        temp: 57.42,
        feels_like: 55.17,
        pressure: 1014,
        humidity: 49,
        dew_point: 37.92,
        uvi: 0,
        clouds: 0,
        visibility: 10000,
        wind_speed: 6.04,
        wind_deg: 334,
        wind_gust: 14.7,
        weather: [
          {id: 800, main: 'Clear', description: 'clear sky', icon: '01n'},
        ],
        pop: 0,
      },
      {
        dt: 1664265600,
        temp: 56.16,
        feels_like: 53.87,
        pressure: 1014,
        humidity: 51,
        dew_point: 37.74,
        uvi: 0,
        clouds: 0,
        visibility: 10000,
        wind_speed: 5.46,
        wind_deg: 337,
        wind_gust: 11.14,
        weather: [
          {id: 800, main: 'Clear', description: 'clear sky', icon: '01n'},
        ],
        pop: 0,
      },
      {
        dt: 1664269200,
        temp: 54.73,
        feels_like: 52.39,
        pressure: 1014,
        humidity: 53,
        dew_point: 37.78,
        uvi: 0,
        clouds: 0,
        visibility: 10000,
        wind_speed: 3.58,
        wind_deg: 342,
        wind_gust: 5.93,
        weather: [
          {id: 800, main: 'Clear', description: 'clear sky', icon: '01n'},
        ],
        pop: 0,
      },
      {
        dt: 1664272800,
        temp: 53.89,
        feels_like: 51.57,
        pressure: 1015,
        humidity: 55,
        dew_point: 37.72,
        uvi: 0,
        clouds: 0,
        visibility: 10000,
        wind_speed: 3.38,
        wind_deg: 344,
        wind_gust: 4.76,
        weather: [
          {id: 800, main: 'Clear', description: 'clear sky', icon: '01n'},
        ],
        pop: 0,
      },
      {
        dt: 1664276400,
        temp: 53.22,
        feels_like: 50.88,
        pressure: 1015,
        humidity: 56,
        dew_point: 37.62,
        uvi: 0,
        clouds: 0,
        visibility: 10000,
        wind_speed: 3.15,
        wind_deg: 352,
        wind_gust: 4.34,
        weather: [
          {id: 800, main: 'Clear', description: 'clear sky', icon: '01n'},
        ],
        pop: 0,
      },
      {
        dt: 1664280000,
        temp: 54.59,
        feels_like: 52.34,
        pressure: 1016,
        humidity: 55,
        dew_point: 38.61,
        uvi: 0.12,
        clouds: 0,
        visibility: 10000,
        wind_speed: 2.82,
        wind_deg: 349,
        wind_gust: 3.78,
        weather: [
          {id: 800, main: 'Clear', description: 'clear sky', icon: '01d'},
        ],
        pop: 0,
      },
      {
        dt: 1664283600,
        temp: 60.49,
        feels_like: 58.32,
        pressure: 1016,
        humidity: 44,
        dew_point: 38.53,
        uvi: 0.71,
        clouds: 0,
        visibility: 10000,
        wind_speed: 4.21,
        wind_deg: 353,
        wind_gust: 7.11,
        weather: [
          {id: 800, main: 'Clear', description: 'clear sky', icon: '01d'},
        ],
        pop: 0,
      },
      {
        dt: 1664287200,
        temp: 65.55,
        feels_like: 63.5,
        pressure: 1017,
        humidity: 36,
        dew_point: 37.63,
        uvi: 1.98,
        clouds: 0,
        visibility: 10000,
        wind_speed: 4.5,
        wind_deg: 360,
        wind_gust: 5.73,
        weather: [
          {id: 800, main: 'Clear', description: 'clear sky', icon: '01d'},
        ],
        pop: 0,
      },
      {
        dt: 1664290800,
        temp: 69.69,
        feels_like: 67.73,
        pressure: 1016,
        humidity: 29,
        dew_point: 35.67,
        uvi: 3.75,
        clouds: 0,
        visibility: 10000,
        wind_speed: 3.76,
        wind_deg: 352,
        wind_gust: 5.46,
        weather: [
          {id: 800, main: 'Clear', description: 'clear sky', icon: '01d'},
        ],
        pop: 0,
      },
      {
        dt: 1664294400,
        temp: 73.18,
        feels_like: 71.33,
        pressure: 1016,
        humidity: 24,
        dew_point: 33.85,
        uvi: 5.48,
        clouds: 0,
        visibility: 10000,
        wind_speed: 3.67,
        wind_deg: 317,
        wind_gust: 5.46,
        weather: [
          {id: 800, main: 'Clear', description: 'clear sky', icon: '01d'},
        ],
        pop: 0,
      },
      {
        dt: 1664298000,
        temp: 75.13,
        feels_like: 73.33,
        pressure: 1016,
        humidity: 21,
        dew_point: 32.18,
        uvi: 6.47,
        clouds: 0,
        visibility: 10000,
        wind_speed: 4.74,
        wind_deg: 307,
        wind_gust: 7.34,
        weather: [
          {id: 800, main: 'Clear', description: 'clear sky', icon: '01d'},
        ],
        pop: 0,
      },
      {
        dt: 1664301600,
        temp: 76.78,
        feels_like: 75.06,
        pressure: 1015,
        humidity: 19,
        dew_point: 30.9,
        uvi: 6.36,
        clouds: 0,
        visibility: 10000,
        wind_speed: 5.23,
        wind_deg: 295,
        wind_gust: 7.9,
        weather: [
          {id: 800, main: 'Clear', description: 'clear sky', icon: '01d'},
        ],
        pop: 0,
      },
      {
        dt: 1664305200,
        temp: 77.65,
        feels_like: 75.96,
        pressure: 1015,
        humidity: 18,
        dew_point: 30.29,
        uvi: 5.24,
        clouds: 0,
        visibility: 10000,
        wind_speed: 5.91,
        wind_deg: 298,
        wind_gust: 9.13,
        weather: [
          {id: 800, main: 'Clear', description: 'clear sky', icon: '01d'},
        ],
        pop: 0,
      },
      {
        dt: 1664308800,
        temp: 78.03,
        feels_like: 76.33,
        pressure: 1014,
        humidity: 17,
        dew_point: 30.54,
        uvi: 3.44,
        clouds: 0,
        visibility: 10000,
        wind_speed: 5.3,
        wind_deg: 303,
        wind_gust: 9.06,
        weather: [
          {id: 800, main: 'Clear', description: 'clear sky', icon: '01d'},
        ],
        pop: 0,
      },
      {
        dt: 1664312400,
        temp: 77.56,
        feels_like: 75.87,
        pressure: 1014,
        humidity: 18,
        dew_point: 30.51,
        uvi: 1.7,
        clouds: 2,
        visibility: 10000,
        wind_speed: 5.08,
        wind_deg: 307,
        wind_gust: 8.97,
        weather: [
          {id: 800, main: 'Clear', description: 'clear sky', icon: '01d'},
        ],
        pop: 0,
      },
      {
        dt: 1664316000,
        temp: 74.7,
        feels_like: 73.04,
        pressure: 1015,
        humidity: 25,
        dew_point: 36.16,
        uvi: 0.54,
        clouds: 23,
        visibility: 10000,
        wind_speed: 4.25,
        wind_deg: 322,
        wind_gust: 6.82,
        weather: [
          {id: 801, main: 'Clouds', description: 'few clouds', icon: '02d'},
        ],
        pop: 0,
      },
      {
        dt: 1664319600,
        temp: 68.72,
        feels_like: 66.65,
        pressure: 1016,
        humidity: 29,
        dew_point: 34.84,
        uvi: 0,
        clouds: 38,
        visibility: 10000,
        wind_speed: 5.97,
        wind_deg: 343,
        wind_gust: 11.16,
        weather: [
          {id: 802, main: 'Clouds', description: 'scattered clouds', icon: '03d'},
        ],
        pop: 0,
      },
      {
        dt: 1664323200,
        temp: 65.35,
        feels_like: 63,
        pressure: 1017,
        humidity: 30,
        dew_point: 32.7,
        uvi: 0,
        clouds: 40,
        visibility: 10000,
        wind_speed: 8.21,
        wind_deg: 357,
        wind_gust: 20.76,
        weather: [
          {id: 802, main: 'Clouds', description: 'scattered clouds', icon: '03n'},
        ],
        pop: 0,
      },
      {
        dt: 1664326800,
        temp: 64.06,
        feels_like: 61.63,
        pressure: 1018,
        humidity: 31,
        dew_point: 32.4,
        uvi: 0,
        clouds: 82,
        visibility: 10000,
        wind_speed: 8.5,
        wind_deg: 3,
        wind_gust: 22.3,
        weather: [
          {id: 803, main: 'Clouds', description: 'broken clouds', icon: '04n'},
        ],
        pop: 0,
      },
      {
        dt: 1664330400,
        temp: 62.33,
        feels_like: 59.81,
        pressure: 1019,
        humidity: 33,
        dew_point: 32.86,
        uvi: 0,
        clouds: 88,
        visibility: 10000,
        wind_speed: 8.19,
        wind_deg: 360,
        wind_gust: 22.15,
        weather: [
          {id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04n'},
        ],
        pop: 0,
      },
      {
        dt: 1664334000,
        temp: 61.2,
        feels_like: 58.71,
        pressure: 1020,
        humidity: 36,
        dew_point: 33.33,
        uvi: 0,
        clouds: 79,
        visibility: 10000,
        wind_speed: 8.52,
        wind_deg: 358,
        wind_gust: 23.58,
        weather: [
          {id: 803, main: 'Clouds', description: 'broken clouds', icon: '04n'},
        ],
        pop: 0,
      },
      {
        dt: 1664337600,
        temp: 60.6,
        feels_like: 58.1,
        pressure: 1020,
        humidity: 37,
        dew_point: 33.67,
        uvi: 0,
        clouds: 84,
        visibility: 10000,
        wind_speed: 7.78,
        wind_deg: 1,
        wind_gust: 22.39,
        weather: [
          {id: 803, main: 'Clouds', description: 'broken clouds', icon: '04n'},
        ],
        pop: 0,
      },
      {
        dt: 1664341200,
        temp: 58.71,
        feels_like: 56.16,
        pressure: 1020,
        humidity: 40,
        dew_point: 34.27,
        uvi: 0,
        clouds: 85,
        visibility: 10000,
        wind_speed: 6.6,
        wind_deg: 8,
        wind_gust: 19.46,
        weather: [
          {id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04n'},
        ],
        pop: 0,
      },
      {
        dt: 1664344800,
        temp: 56.7,
        feels_like: 54.14,
        pressure: 1020,
        humidity: 44,
        dew_point: 34.74,
        uvi: 0,
        clouds: 82,
        visibility: 10000,
        wind_speed: 5.7,
        wind_deg: 15,
        wind_gust: 16.44,
        weather: [
          {id: 803, main: 'Clouds', description: 'broken clouds', icon: '04n'},
        ],
        pop: 0,
      },
      {
        dt: 1664348400,
        temp: 56.79,
        feels_like: 54.23,
        pressure: 1021,
        humidity: 44,
        dew_point: 34.95,
        uvi: 0,
        clouds: 100,
        visibility: 10000,
        wind_speed: 6.02,
        wind_deg: 13,
        wind_gust: 17.13,
        weather: [
          {id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04n'},
        ],
        pop: 0,
      },
      {
        dt: 1664352000,
        temp: 55.83,
        feels_like: 53.22,
        pressure: 1021,
        humidity: 45,
        dew_point: 34.66,
        uvi: 0,
        clouds: 100,
        visibility: 10000,
        wind_speed: 6.02,
        wind_deg: 17,
        wind_gust: 17.34,
        weather: [
          {id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04n'},
        ],
        pop: 0,
      },
      {
        dt: 1664355600,
        temp: 55.45,
        feels_like: 52.81,
        pressure: 1021,
        humidity: 45,
        dew_point: 34.21,
        uvi: 0,
        clouds: 100,
        visibility: 10000,
        wind_speed: 6.42,
        wind_deg: 17,
        wind_gust: 19.66,
        weather: [
          {id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04n'},
        ],
        pop: 0,
      },
      {
        dt: 1664359200,
        temp: 54.68,
        feels_like: 52.02,
        pressure: 1021,
        humidity: 46,
        dew_point: 34.32,
        uvi: 0,
        clouds: 100,
        visibility: 10000,
        wind_speed: 6.35,
        wind_deg: 22,
        wind_gust: 19.75,
        weather: [
          {id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04n'},
        ],
        pop: 0,
      },
      {
        dt: 1664362800,
        temp: 54.14,
        feels_like: 51.46,
        pressure: 1022,
        humidity: 47,
        dew_point: 34.36,
        uvi: 0,
        clouds: 100,
        visibility: 10000,
        wind_speed: 6.76,
        wind_deg: 23,
        wind_gust: 21.43,
        weather: [
          {id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04n'},
        ],
        pop: 0,
      },
      {
        dt: 1664366400,
        temp: 54.23,
        feels_like: 51.6,
        pressure: 1022,
        humidity: 48,
        dew_point: 34.84,
        uvi: 0.1,
        clouds: 100,
        visibility: 10000,
        wind_speed: 7.76,
        wind_deg: 27,
        wind_gust: 23.44,
        weather: [
          {id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04d'},
        ],
        pop: 0,
      },
      {
        dt: 1664370000,
        temp: 57.31,
        feels_like: 54.81,
        pressure: 1023,
        humidity: 44,
        dew_point: 35.51,
        uvi: 0.63,
        clouds: 100,
        visibility: 10000,
        wind_speed: 9.01,
        wind_deg: 32,
        wind_gust: 23.26,
        weather: [
          {id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04d'},
        ],
        pop: 0,
      },
      {
        dt: 1664373600,
        temp: 61.72,
        feels_like: 59.32,
        pressure: 1023,
        humidity: 37,
        dew_point: 35.01,
        uvi: 1.79,
        clouds: 100,
        visibility: 10000,
        wind_speed: 10.78,
        wind_deg: 36,
        wind_gust: 20.02,
        weather: [
          {id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04d'},
        ],
        pop: 0,
      },
      {
        dt: 1664377200,
        temp: 64.31,
        feels_like: 61.95,
        pressure: 1023,
        humidity: 32,
        dew_point: 33.96,
        uvi: 3.4,
        clouds: 100,
        visibility: 10000,
        wind_speed: 11.14,
        wind_deg: 33,
        wind_gust: 15.77,
        weather: [
          {id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04d'},
        ],
        pop: 0,
      },
      {
        dt: 1664380800,
        temp: 66.97,
        feels_like: 64.63,
        pressure: 1023,
        humidity: 27,
        dew_point: 32.13,
        uvi: 4.45,
        clouds: 100,
        visibility: 10000,
        wind_speed: 10.49,
        wind_deg: 30,
        wind_gust: 13.58,
        weather: [
          {id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04d'},
        ],
        pop: 0,
      },
      {
        dt: 1664384400,
        temp: 68.94,
        feels_like: 66.65,
        pressure: 1022,
        humidity: 24,
        dew_point: 30.56,
        uvi: 5.26,
        clouds: 100,
        visibility: 10000,
        wind_speed: 10.27,
        wind_deg: 26,
        wind_gust: 12.75,
        weather: [
          {id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04d'},
        ],
        pop: 0,
      },
      {
        dt: 1664388000,
        temp: 69.93,
        feels_like: 67.66,
        pressure: 1022,
        humidity: 22,
        dew_point: 29.48,
        uvi: 5.17,
        clouds: 100,
        visibility: 10000,
        wind_speed: 10.94,
        wind_deg: 19,
        wind_gust: 13.22,
        weather: [
          {id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04d'},
        ],
        pop: 0,
      },
      {
        dt: 1664391600,
        temp: 69.19,
        feels_like: 66.85,
        pressure: 1021,
        humidity: 22,
        dew_point: 29.34,
        uvi: 4.02,
        clouds: 100,
        visibility: 10000,
        wind_speed: 11.1,
        wind_deg: 20,
        wind_gust: 13.76,
        weather: [
          {id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04d'},
        ],
        pop: 0,
      },
      {
        dt: 1664395200,
        temp: 68.54,
        feels_like: 66.22,
        pressure: 1021,
        humidity: 24,
        dew_point: 29.95,
        uvi: 2.64,
        clouds: 100,
        visibility: 10000,
        wind_speed: 10.6,
        wind_deg: 24,
        wind_gust: 15.01,
        weather: [
          {id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04d'},
        ],
        pop: 0,
      },
      {
        dt: 1664398800,
        temp: 68.83,
        feels_like: 66.58,
        pressure: 1021,
        humidity: 25,
        dew_point: 31.32,
        uvi: 1.29,
        clouds: 100,
        visibility: 10000,
        wind_speed: 10.2,
        wind_deg: 30,
        wind_gust: 15.9,
        weather: [
          {id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04d'},
        ],
        pop: 0,
      },
      {
        dt: 1664402400,
        temp: 67.1,
        feels_like: 64.78,
        pressure: 1021,
        humidity: 27,
        dew_point: 32.27,
        uvi: 0.35,
        clouds: 100,
        visibility: 10000,
        wind_speed: 9.1,
        wind_deg: 32,
        wind_gust: 16.62,
        weather: [
          {id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04d'},
        ],
        pop: 0,
      },
    ],
    daily: [
      {
        dt: 1664211600,
        sunrise: 1664190851,
        sunset: 1664234097,
        moonrise: 1664193000,
        moonset: 1664236380,
        moon_phase: 0.02,
        temp: {
          day: 81.36,
          min: 59.65,
          max: 84.42,
          night: 67.12,
          eve: 72.48,
          morn: 59.65,
        },
        feels_like: {day: 80.04, night: 65.64, eve: 71.55, morn: 59.36},
        pressure: 1011,
        humidity: 30,
        dew_point: 47.48,
        wind_speed: 10.67,
        wind_deg: 294,
        wind_gust: 24.49,
        weather: [
          {id: 800, main: 'Clear', description: 'clear sky', icon: '01d'},
        ],
        clouds: 2,
        pop: 0.63,
        uvi: 6.55,
      },
      {
        dt: 1664298000,
        sunrise: 1664277295,
        sunset: 1664320411,
        moonrise: 1664283180,
        moonset: 1664324460,
        moon_phase: 0.06,
        temp: {
          day: 75.13,
          min: 53.22,
          max: 78.03,
          night: 61.2,
          eve: 68.72,
          morn: 53.22,
        },
        feels_like: {day: 73.33, night: 58.71, eve: 66.65, morn: 50.88},
        pressure: 1016,
        humidity: 21,
        dew_point: 32.18,
        wind_speed: 8.52,
        wind_deg: 358,
        wind_gust: 23.58,
        weather: [
          {id: 800, main: 'Clear', description: 'clear sky', icon: '01d'},
        ],
        clouds: 0,
        pop: 0,
        uvi: 6.47,
      },
      {
        dt: 1664384400,
        sunrise: 1664363740,
        sunset: 1664406724,
        moonrise: 1664373540,
        moonset: 1664412720,
        moon_phase: 0.09,
        temp: {
          day: 68.94,
          min: 54.14,
          max: 69.93,
          night: 59.22,
          eve: 64.04,
          morn: 54.14,
        },
        feels_like: {day: 66.65, night: 56.53, eve: 61.56, morn: 51.46},
        pressure: 1022,
        humidity: 24,
        dew_point: 30.56,
        wind_speed: 11.14,
        wind_deg: 33,
        wind_gust: 24.7,
        weather: [
          {id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04d'},
        ],
        clouds: 100,
        pop: 0,
        uvi: 5.26,
      },
      {
        dt: 1664470800,
        sunrise: 1664450185,
        sunset: 1664493038,
        moonrise: 1664464020,
        moonset: 1664501340,
        moon_phase: 0.13,
        temp: {
          day: 70.48,
          min: 55.02,
          max: 71.02,
          night: 61.95,
          eve: 65.17,
          morn: 55.06,
        },
        feels_like: {day: 68.88, night: 59.95, eve: 63.27, morn: 53.08},
        pressure: 1022,
        humidity: 35,
        dew_point: 41.58,
        wind_speed: 16.26,
        wind_deg: 50,
        wind_gust: 31.41,
        weather: [
          {id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04d'},
        ],
        clouds: 100,
        pop: 0,
        uvi: 5.99,
      },
      {
        dt: 1664557200,
        sunrise: 1664536630,
        sunset: 1664579352,
        moonrise: 1664554620,
        moonset: 1664590260,
        moon_phase: 0.17,
        temp: {
          day: 58.84,
          min: 56.53,
          max: 61.18,
          night: 59.63,
          eve: 59.58,
          morn: 56.53,
        },
        feels_like: {day: 58.46, night: 59.81, eve: 59.56, morn: 55.18},
        pressure: 1019,
        humidity: 86,
        dew_point: 54.64,
        wind_speed: 17.65,
        wind_deg: 46,
        wind_gust: 39.86,
        weather: [
          {id: 501, main: 'Rain', description: 'moderate rain', icon: '10d'},
        ],
        clouds: 100,
        pop: 1,
        rain: 9.57,
        uvi: 2.17,
      },
      {
        dt: 1664643600,
        sunrise: 1664623075,
        sunset: 1664665666,
        moonrise: 1664645220,
        moonset: 1664679840,
        moon_phase: 0.2,
        temp: {
          day: 64.15,
          min: 59.92,
          max: 64.71,
          night: 63.86,
          eve: 64.71,
          morn: 61.57,
        },
        feels_like: {day: 64.87, night: 64.54, eve: 65.48, morn: 62.04},
        pressure: 1012,
        humidity: 98,
        dew_point: 63.3,
        wind_speed: 14.18,
        wind_deg: 58,
        wind_gust: 34.14,
        weather: [
          {id: 501, main: 'Rain', description: 'moderate rain', icon: '10d'},
        ],
        clouds: 100,
        pop: 1,
        rain: 27.95,
        uvi: 3,
      },
      {
        dt: 1664730000,
        sunrise: 1664709521,
        sunset: 1664751980,
        moonrise: 1664735580,
        moonset: 0,
        moon_phase: 0.25,
        temp: {
          day: 68.49,
          min: 62.56,
          max: 68.49,
          night: 62.56,
          eve: 63.68,
          morn: 64.81,
        },
        feels_like: {day: 69.22, night: 63.12, eve: 64.35, morn: 65.61},
        pressure: 1011,
        humidity: 89,
        dew_point: 64.85,
        wind_speed: 12.66,
        wind_deg: 58,
        wind_gust: 31.18,
        weather: [
          {id: 500, main: 'Rain', description: 'light rain', icon: '10d'},
        ],
        clouds: 99,
        pop: 1,
        rain: 10.15,
        uvi: 3,
      },
      {
        dt: 1664816400,
        sunrise: 1664795967,
        sunset: 1664838295,
        moonrise: 1664825460,
        moonset: 1664769840,
        moon_phase: 0.27,
        temp: {
          day: 61.18,
          min: 59.16,
          max: 61.56,
          night: 59.16,
          eve: 59.94,
          morn: 59.31,
        },
        feels_like: {day: 61.27, night: 58.82, eve: 59.67, morn: 59.54},
        pressure: 1013,
        humidity: 91,
        dew_point: 58.32,
        wind_speed: 6.71,
        wind_deg: 359,
        wind_gust: 16.37,
        weather: [
          {id: 500, main: 'Rain', description: 'light rain', icon: '10d'},
        ],
        clouds: 100,
        pop: 0.83,
        rain: 1.39,
        uvi: 3,
      },
    ],
    // alerts: [
    //   {
    //     sender_name: 'NWS Charlotte',
    //     event: 'Small Craft Advisory',
    //     start: 1646344800,
    //     end: 1646380800,
    //     description:
    //       '...SMALL CRAFT ADVISORY REMAINS IN EFFECT FROM 5 PM THIS\nAFTERNOON TO 3 AM EST FRIDAY...\n* WHAT...North winds 15 to 20 kt with gusts up to 25 kt and seas\n3 to 5 ft expected.\n* WHERE...Coastal waters from Little Egg Inlet to Great Egg\nInlet NJ out 20 nm, Coastal waters from Great Egg Inlet to\nCape May NJ out 20 nm and Coastal waters from Manasquan Inlet\nto Little Egg Inlet NJ out 20 nm.\n* WHEN...From 5 PM this afternoon to 3 AM EST Friday.\n* IMPACTS...Conditions will be hazardous to small craft.',
    //   },
    // ],
  }
  
  export default WEATHER_DATA;